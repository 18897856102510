<template>
    <v-row>
      <v-col cols="12">
          <v-list 
              v-for="item in stakedAssets"
              :key="item"
          >
          {{item.token}}
            <div v-if="!item.isLocal" >
              <v-icon
                end
                icon="mdi-cancel"
                color="red"
              ></v-icon>
              <span v-if="!item.isLocal">
                Stake for this token is missing
              </span>
            </div>
              <v-btn
                @click="withdrawStake(item)"
              >
                Withdraw Stake
              </v-btn>
          </v-list>
      </v-col>
    </v-row>
  </template>
  
  <script>
  export default {
      data(){
          return {
              
          }
      },
      mounted(){
        this.getStakedAssets();
      },
      methods:{
        withdrawStake(item){
          if(this.localWallet){
            this.$store.dispatch('stakeData/withdraw',
            {
              project_id:this.currentProject.id,
              token_address:item.token,
              local_wallet_id:this.localWallet._publicKey
            }).then(() => {}, error => {console.log(error)});
          }
          
        },
        getStakedAssets(){
          if(this.currentProject){
            this.$store.dispatch('stakeData/getAll',{project_id:this.currentProject.id}).then(() => {}, error => {console.log(error)});
          }
        }
      },
      computed:{
        currentProject(){
          return this.$store.getters['projectData/current'];
        },
        stakedAssets(){
          return this.$store.getters['stakeData/all'];
        },
        localWallet () {
            return this.$store.getters['localWalletData/localWalletCurrent'];
        }
      }, 
      watch:{
          currentProject(){
             this.getStakedAssets(); 
          },
          
      }
  }
  </script>
  
  