import store from '@/store/index'
import BrowserWalletService from '@/Services/BrowserWalletService'
import axios from '@/Services/axiosService';

const localStorageUserKey = process.env.VUE_APP_LOCAL_STORAGE_KEY 

export default{
    urlProjectID:null,
    async init(urlProjectID){
        
        this.urlProjectID = urlProjectID;
        await BrowserWalletService.updateLocalWallet();
        await this.handleAuthentication();
    },
    async handleAuthentication(){
        let tokenStoredInBrowser = this.userCredentialsFoundInBrowserStorage();
  
        if(tokenStoredInBrowser){
          
          this.setAxiosAuthHeaderToStoredToken(tokenStoredInBrowser)
          
          let userAndProjectData= await this.fetchUserAndProjectData();
          this.setAxiosAuthHeaderToStoredToken(userAndProjectData.user.authorisation.token)
          this.setCurrentProjectFromUserData(userAndProjectData);      
          // .then((userAndProjectData)=>{
          //   console.log('userAndProjectData',userAndProjectData)
          //   this.setAxiosAuthHeaderToStoredToken(userAndProjectData.user.authorisation.token)
          //   this.setCurrentProjectFromUserData(userAndProjectData);      
          // }); //TODO handle expired token or error.
          
          
  
        }else{ 
          if(BrowserWalletService.browserWalletPublicKey){
              let userAndProjectData = await this.createOrFindGuestUser();
              console.log('++usersdsd',userAndProjectData)
              this.setAxiosAuthHeaderToStoredToken(userAndProjectData.user.authorisation.token)
              this.setCurrentProjectFromUserData(userAndProjectData);      
          }
        }
      },
      userCredentialsFoundInBrowserStorage(){
        return localStorage.getItem(localStorageUserKey);
    },
    async setAxiosAuthHeaderToStoredToken(tokenToStoreInBrowser){
        localStorage.setItem(localStorageUserKey, tokenToStoreInBrowser);
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tokenToStoreInBrowser;
    },
    async removeAxiosAuthHeaderToStoredToken(){
      localStorage.removeItem(localStorageUserKey);
      axios.defaults.headers.common['Authorization'] = 'Bearer ';
    },
    async fetchUserAndProjectData(){
        let userAndProjectData;
        try {
          userAndProjectData =  await store.dispatch('userData/getCurrentUser',this.urlProjectID)
        } catch (error) {
          await this.removeAxiosAuthHeaderToStoredToken();
          this.handleAuthentication();
        }
      
        return userAndProjectData;
    },
    setCurrentProjectFromUserData(userAndProjectData){
        store.dispatch('projectData/setCurrent',userAndProjectData.project);
      },
      async createOrFindGuestUser(){
        if(BrowserWalletService.browserWalletPublicKey){
            let authData = await store.dispatch('userData/handleGuest',
            {
              project:this.urlProjectID,
              user:BrowserWalletService.browserWalletPublicKey
            }
          )
          return authData;
        }else{
            console.log("Error getting local wallet address")
        }
        return false;
      },
  
}