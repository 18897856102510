<template>
    <v-container>
        
        <v-row>
            <project-detail-block />
        </v-row>
        
        <v-row>
            <v-col cols="9" class="pageLeft">
                <v-row>
                    <v-col cols="9">
                        <h2 class="listTitle">{{ listTitle }}</h2>
                    </v-col>
                    <v-col cols="3">
                        <v-btn-toggle v-model="toggle_exclusive">
                            <v-btn >
                                <span>Stakable</span>
                            </v-btn>

                            <v-btn>
                                <span>Staked</span>
                            </v-btn>
                        </v-btn-toggle>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <user-assets 
                            v-if="project"
                            :listmode=selectedListMode 
                        />
                    </v-col>
                </v-row>
            </v-col>

            <v-col cols="3" class="pageRight sidebar">
                <v-row>
                    <v-col cols="12">
                        <h3>Rewards</h3>
                        <reward-list/>
                         <!-- this seems to be hammering the server, consider returning this content with another call (all stakes perhaps) -->
                    </v-col>
                        

                </v-row>
               
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <feedback-bar />
            </v-col>
        </v-row>
        
    </v-container>

</template>

<script>

import UserService from '@/Services/UserService';

export default {
    data(){
        return {
            projectID:null,
            selectedListMode:'stakeable',
            listTitle:'',
            toggle_exclusive:0
        }
    },
    async mounted(){
        console.log('HOME!!')
        if(this.$route.params.projectID){
            this.projectID = this.$route.params.projectID;
            console.log('Project ID taken from URL')
        }else{
            const appElement = document.getElementById('nxs-staking-staker');
            this.projectID = appElement.getAttribute('data-project-id');
            console.log('PROJECT ID TAKEN FROM DATA ATTRIBUTE ' )
        }
        console.log('this.projectID',this.projectID)

        this.getListTitle();
        await UserService.init(this.projectID);
    },
    methods:{

        listModeChanged(){
            this.getListTitle();
        },
          
        getProjectIDFromURL(){
        let projectID = process.env.VUE_APP_PROJECT_ID;
            console.log('getting IDQ!')
            console.log(projectID)

        // if(this.$route.params.projectId){
        //     projectID = this.$route.params.projectId;
        // }

        return projectID;
        },

        getListTitle(){
            switch (this.selectedListMode) {
                case 'stakeable':
                    this.listTitle = 'Stakeable Assets';
                    break;
                case 'staked':
                    this.listTitle = 'Staked Assets';
                    break;
                default:
                    break;
            }
        }
    },
    computed:{
        project(){
            return this.$store.getters['projectData/current'];
        }
    },  
    watch:{
        selectedListMode(){
            this.listModeChanged()
        },
        toggle_exclusive(val){
            if(val == 0 || val  == undefined){
                this.selectedListMode = 'stakeable'
            }else{
                this.selectedListMode = 'staked'
            }
            console.log(val)
        }
    }
}
</script>

<style scoped>
    .listTitle{
        text-align: left;
    }
    .pageRight.sidebar{
        text-align: left;
    }
</style>