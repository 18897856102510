import store from '@/store/index'

export default{
    browserWallet : null,
    browserWalletPublicKey : null,
    async updateLocalWallet(){
        if(window.solana){
            await window.solana.connect(); // TODO handle errors here?
            await this.storeBrowserWalletInGlobalStore();
            this.browserWalletPublicKey = this.browserWallet._publicKey
        }
    },
    async storeBrowserWalletInGlobalStore(){
      await store.dispatch('localWalletData/localWalletSetCurrent',window.solana).then(() => {
        this.browserWallet = window.solana
      }, error => {console.log(error)});
    },

}