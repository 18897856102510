import * as mutation from './mutationTypes'
import axios from '@/Services/axiosService'

const apiAddress_staking = process.env.VUE_APP_STAKING_SERVICE_BASE;

const userProjectWalletData  = {
    namespaced: true,
    state: {
        all:null,
        current:null,
    },
    getters: {
        current : state =>{
            return state.current;
        },
        all : state =>{
            return state.all;
        }
    },
    mutations: {
        [mutation.SET_CURRENT] (state,data){
            console.log('SETTING LOCAL WALLET FTA TO ', data, data.address)
            state.current = data
          },
          [mutation.SET_ALL] (state,data){
            let theData = JSON.parse(JSON.stringify(data))
            state.all = theData;
          },
    },
    actions: {
        async get({commit},project_id){
            return await axios.get(apiAddress_staking + '/projects/' + project_id + '/user/wallet')
            .then(resp=>{
                commit(
                    mutation.SET_CURRENT,
                    resp.data
                );
                return resp.data;
            });
        },
        getAll({commit}){
            axios.get('/user-project-wallet/get')
            .then(resp=>{
                console.log("resp");
                commit(
                    mutation.SET_ALL,
                    resp.data
                );
            });
        },
        // create({commit},data){
            
        //     axios.post('/user-project-wallet/create',data).then(resp=>{
        //         commit(
        //             mutation.SET_CURRENT,
        //             resp.data
        //         );
        //         return resp;
        //     });
        // }
    }
}
export {userProjectWalletData};
