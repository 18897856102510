<template>
    <v-row>
        <v-col cols="12">
            Project List:<span v-if="currentProject">{{currentProject.name}}</span>
            <v-select
            label="Select"
            :items="allProjects"
            item-title="name"
            item-value="id"
            @update:modelValue="setCurrentProject($event)"
            ></v-select>
            <v-btn
                @click="test()"
            >
                test
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>

    import axios from 'axios'

export default {
    data(){
        return {
            select:null
        }        
    },
    mounted(){
        this.getAll();
    }, 
    methods:{
        test(){
            axios.post('http://localhost:8082/test',{thing:"hellos"});
        },  
        setCurrentProject(projectID){
            this.allProjects.forEach(project => {
                if(project.id == projectID){
                    this.$store.dispatch('projectData/setCurrent',project).then(error => {console.log(error)});
                }    
            });
        },  
        getAll(){
            this.$store.dispatch('projectData/getAll').then(error => {console.log(error)});
        }
    },
    computed:{
        allProjects(){
            return this.$store.getters['projectData/all'];
        },
        currentProject (){
            return this.$store.getters['projectData/current'];
        }
    }
}
</script>

