<template>
  <v-progress-linear
      model-value="15"
      color="pink"
      reverse
      v-if="show"
    ></v-progress-linear>
</template>

<script>
export default {
    data() {
    return {
      show: false,
      message: "Loading...",
    };
  },
}
</script>

<style>

</style>