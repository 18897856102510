//https://github.com/solstar-tech/easy-spl
import { 
    Connection, 
    PublicKey
} from '@solana/web3.js';

import * as spl from 'easy-spl'

// import axiosService from '@/Services/axiosService'
// const chain = get from axios.
const connection = new Connection(process.env.VUE_APP_WEB3_ENDPOINT, 'confirmed');



const TokenTransfer = {
    
    async transferSelectedToken(tokenAddress,userProjectWalletAddress,amount = 1){

        let  response = {
            transactionAddress:'',
            error:null
        }
        try {
            const currentLocalUser = spl.Wallet.fromWallet(connection, window.solana)
            response.transactionAddress = await currentLocalUser.transferToken(new PublicKey(tokenAddress), new PublicKey(userProjectWalletAddress), amount);    
        } catch (error) {
            console.log(error)
            response.error = true;
        }

        return response;
    }
    
}

export default TokenTransfer;