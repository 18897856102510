import * as mutation from './mutationTypes'

const alertData  = {
    namespaced: true,
    state: {
        current:null,
        all:[]
    },
    getters: {
        current : state =>{
            return state.current;
        },
        all : state =>{
            return state.all;
        }
    },
    mutations: {
        [mutation.SET_CURRENT] (state,message){
            state.current = message;
            state.all.push(message)
            
            let index = state.all.length - 1;

            console.log("added an item to store")
            setTimeout(() => {
                state.all.splice(index,1)
            }, 2500);
          }
    },
    actions: {
        add({commit},message){
            commit(mutation.SET_CURRENT,message);
        }
    }
}
export {alertData};
