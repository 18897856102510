import { createStore } from 'vuex'

import {projectData} from './projects/storeData';
import {userData} from './user/storeData';
import {projectWalletData } from './projectWallets/storeData';
import {localWalletData} from './localWallet/storeData';
import {userProjectWalletData} from './userProjectWallets/storeData';
import {stakeData} from './stake/storeData';
import {stakeRewardsData} from './stakeRewards/storeData';
import {alertData} from './alerts/storeData';
import {errorData} from './errors/store';

export default createStore({
  modules:{
    projectData,
    userData,
    projectWalletData,
    localWalletData,
    userProjectWalletData,
    stakeData,
    stakeRewardsData,
    alertData,
    errorData
  }
})