<template>
    <v-row>
          <v-col cols="2">
              <v-btn
                  @click="getAll()"
              >
                  Refresh List
              </v-btn>
  
              <v-btn
                  @click="create()"
              >
                  Create
              </v-btn>
          </v-col>
  
          <v-col cols="10">
              <v-table
                  v-if="wallets"
                  :headers="headers"
                  :items="wallets"
                  @click:row="rowClick"
              >
                  <template v-slot:items="props">
                      <td>{{ props.item.user_id }}</td>
                      <td>{{ props.item.project_id }}</td>
                      <td>{{ props.item.wallet_id }}</td>
                      
                  </template>
          
          </v-table>
          </v-col>
      </v-row>
  </template>
  
  <script>
  export default {
      data(){
          return {
              headers:[
                  { text: 'user_id', value: 'user_id' },
                  { text: 'project_id', value: 'project_id' },
                  { text: 'wallet_id', value: 'wallet_id' },
              ]
          }
      },
      mounted(){
  
      },
      methods :{
          rowClick(item){
              this.$store.dispatch('projectData/get',{project_id:item.project_id})
              .then(() => {}, error => {
                  console.log(error)
              });
          },
          getAll(){
            
              this.$store.dispatch('pwGetAll')
              .then((data) => {
                  console.log('data',data)
              }, error => {
                  console.log(error)
              });
          },
          create(){
              this.$store.dispatch('projectWalletData/create',{project_id:2})
              .then(() => {}, error => {
                  console.log(error)
              });
          }
      },
      computed: {
          wallets () {
            return this.$store.getters['projectWalletData/all'];
          }
        },
  }
  </script>
  
  