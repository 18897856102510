import * as mutation from './mutationTypes'

const localWalletData  = {
    namespaced:true,
    state: {
        localWalletCurrent:null
    },
    getters:{
        localWalletCurrent : state =>{
            return state.localWalletCurrent;
        },
    },
    mutations:{
        [mutation.SET_CURRENT] (state,data){
            let theData = JSON.parse(JSON.stringify(data))
            state.localWalletCurrent = theData;
          },
    },
    actions:{
        localWalletSetCurrent({commit},data){
            commit(
                mutation.SET_CURRENT,
                data
            );
        }
    }
}


export {localWalletData};