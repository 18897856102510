<template>
  <v-container>
    <v-snackbar
      v-model="snackbar"
      multi-line
      :timeout="3000"

    >
    
      <p 
        v-for="item in alertItems"
        :key="item"
        :title="item"
      >
          {{item}}
      </p>

      <template v-slot:actions>
        <v-btn
          color="red"
          variant="text"
          @click="(snackbar = false)"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
    data(){
        return {
            snackbar:false,
            alertItems:[]
        }
    },
    mounted(){
    },
    computed:{
      alerts(){
        return this.$store.getters['alertData/all'];
      },
  },
  watch:{
    alerts:{
      deep: true,
      handler: function(newVal) {
        this.snackbar = true
        this.alertItems = newVal;
      }
    }
  }
}
</script>

<style>

</style>