import 'vuetify/styles' // Global CSS has to be imported
import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import router from './router'
import store from './store'


import helpers from '@/Services/helpers'
import axios from '@/Services/axiosService'
axios.defaults.baseURL = process.env.VUE_APP_API_BASE;

//Components

//Layout
import FooterLayout from './Components/Layout/FooterLayout'
import LocalWalletActionBar from './Components/LocalWallet/LocalWalletActionBar'
// import UserBar from './Components/Layout/UserBar'
import FeedbackBar from './Components/Layout/FeedbackBar'


//Projects
import ProjectDetailBlock from './Components/Projects/ProjectDetailBlock'
import ProjectSelectList from './Components/Projects/ProjectSelectList'

//Project Wallets
import WalletTable from './Components/Wallet/WalletTable';

//Assets
import StakeableAssets from './Components/Assets/StakeableAssets';
import StakedAssets from './Components/Assets/StakedAssets';
import UserAssets from './Components/Assets/UserAssets';
import AssetCard from './Components/Assets/AssetCard';

//Rewards
import RewardList from './Components/Rewards/RewardList';

//elements
import LoadingSpinner from './Components/Elements/LoadingSpinner';

import globalMixin from '@/Services/helpers';

import { Buffer } from 'buffer';
window.Buffer = Buffer;


loadFonts()

const app = createApp(App).use(store).use(router).use(vuetify)

app.mixin(globalMixin)

// app.component('loading-indicator', require('').default);

app.component('error-snackbar', require('./Components/Layout/ErrorSnackbar').default);

app.component('project-select-list',ProjectSelectList);
app.component('project-detail-block',ProjectDetailBlock);

app.component("project-wallet-table", WalletTable);

app.component("local-wallet-action-bar",LocalWalletActionBar);
app.component("footer-layout", FooterLayout);
// app.component("user-bar", UserBar);
app.component("feedback-bar", FeedbackBar);

app.component("project-stakeable-assets", StakeableAssets);

app.component("user-assets", UserAssets);
app.component("user-asset-card", AssetCard);

app.component("project-staked-assets", StakedAssets);
app.component("loading-spinner", LoadingSpinner);

app.component("reward-list", RewardList);

const instance = axios.create({});
// instance.vueObject = app;
app.config.globalProperties.axios=instance
app.config.globalProperties.nxsHelpers=helpers;

app.config.errorHandler = function (err, vm, info) {
    console.error('NNError:', err);
    console.error('NNInfo:', info);
  };

app.mount('#nxs-staking-staker')