<template>
    <v-row class="localWalletActionBar">
        <v-col cols="12">
            <v-list>
                <v-list-item>
                    <v-list-item-title>
                        Local Wallet : 
                        <span v-if="!localWallet">None Detected</span>
                        <span v-if="localWallet && localWallet._publicKey">{{localWallet._publicKey}}</span>
                    </v-list-item-title>
                </v-list-item>

            </v-list>
        </v-col>
    </v-row>
</template>

<script>
import { Buffer } from 'buffer';
window.Buffer = Buffer;

export default {
    data(){
        return {

        }
    },
    created(){
        this.updateLocalWallet()
    },
    methods : {
        async updateLocalWallet(){
            if(window.solana){
                await window.solana.connect();
                this.$store.dispatch('localWalletData/localWalletSetCurrent',window.solana).then(() => {}, error => {console.log(error)});
            }
        }
    },
    computed :{
        localWallet () {
            return this.$store.getters['localWalletData/localWalletCurrent'];
          }
    },
}
</script>
