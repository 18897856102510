<template>
    <v-container
    fluid
    :v-if="(project && project.assets)"
    >
        <v-row>
            <v-col 
                :v-if="(i.metaData != null)"
                v-for="(item, i) in walletAssets"
                :key="i"
                cols="3"
            >
                <user-asset-card  :v-if="(i.metaData)" :asset="item" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>



import UserAssetService from '@/Services/UserAssetService';
import ChainAssetDataService from '@/Services/ChainAssetData'

export default {
    props:['listmode', "authMode"],
    data(){
        return {
            walletAssets:[],
            listTitle:'',
        }
    },
    async mounted(){
        await this.getUserElligbleAssets().then(()=>{});
    },
    methods:{
        async getStakedAssets(){
            await UserAssetService.getStakedUserAssets(this.project.id).then((stakedUserAssets)=>{
                let stakedAssetList = [];
                
                stakedUserAssets.forEach(stakedAsset => {
                    stakedAssetList.push(
                        { 
                                address:stakedAsset.token,
                                metaData:null,
                                image:null,
                                uriData:null,
                                isStaked:true,
                                stakingStatus:false,
                        }
                    );
                });
   
                // TODO check that this blockchain token list isn't paged
                ChainAssetDataService.getMetaDataFromTokenList(stakedAssetList).then((userAssetsMetaData)=>{
                    userAssetsMetaData.forEach(metaData => {
                        stakedAssetList.forEach(stakedAsset => {
                           
                            if(metaData.mintAddress.toString() == stakedAsset.address){
                                // stakedAsset.metaData = metaData;
                                // this.walletAssets.push(stakedAsset);
                                UserAssetService.getURIData(metaData,stakedAsset)
                                .then((returnData)=>{
                                    this.walletAssets.push(returnData)    
                                });
                            }
                        });
                    });
                })
            
            })
            
            
        },
        async getUserElligbleAssets(){ // Returns STAKEABLE assets (not staked already)
            let vm  = this;
            UserAssetService.listmode = this.listmode;
            
            
            let usableAssetsInBrowserWallet = await UserAssetService.getChainTokensInWalletAndAddStakedStatus();
            let userAssetsMetaData = await ChainAssetDataService.getMetaDataFromTokenList(usableAssetsInBrowserWallet) // TODO check that this blockchain token list isn't paged

            let userAssetsElligibleForProject = UserAssetService.getUserAssetsElligibleForProject(userAssetsMetaData);
            
            userAssetsElligibleForProject.forEach(eligibleUserAsset => {
                if(eligibleUserAsset){
            
                    usableAssetsInBrowserWallet.forEach(walletAsset => {                    
                        
                        if(walletAsset.address == eligibleUserAsset.mintAddress.toString()){
                            walletAsset.metaData = eligibleUserAsset;
                            if(eligibleUserAsset.uri){

                                UserAssetService.getURIData(eligibleUserAsset,walletAsset)
                                .then((returnData)=>{
                                    vm.walletAssets.push(returnData)    
                                });
                            }
                        }
                    });
                }
            });
        }

    },
    computed:{
        localWallet () {
            return this.$store.getters['localWalletData/localWalletCurrent'];
        },
        project(){
            return this.$store.getters['projectData/current'];
        },
        stakedAssets(){
            return this.$store.getters['stakeData/all'];
      },
    },
    watch:{
        project(){
            
            this.walletAssets = [];
            this.getUserElligbleAssets()
        },
        stakedAssets(){
            // this.getWalletAssets();
        },
        listmode(val){
            this.walletAssets = [];

            if(val == 'stakeable'){
                this.getUserElligbleAssets()
            }else{
                this.getStakedAssets();
            }
        }
    }
}
</script>

<style>

</style>