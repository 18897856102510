
import * as mutation from './mutations'
import moment from 'moment'
const errorData = {
    namespaced: true,
    state: ()=>({ 
        allErrors:[],
        
    }),
    mutations: {
        [mutation.ADD_ERROR] (state,data){
            let oldErrors = state.allErrors;
            data.timestamp=moment(moment().format('x'), 'x');
            let currentTime = moment(moment().format('x'), 'x');
            oldErrors = oldErrors.filter(function(item) {
                var difference = currentTime.diff(item.timestamp, 'seconds');
                if(difference <7){
                    return item;
                }
              });

            oldErrors.push(data);
            state.allErrors=[...oldErrors]
          },
     },
    actions:{

        addError({commit},data){
            
            commit(mutation.ADD_ERROR,data);
        }
     },
    getters:{  
        allErrors : state =>{
            return state.allErrors;
        }
    }
}

export {errorData};