import { createRouter, createWebHistory } from 'vue-router'

import HomePage from '../Pages/HomePage'

const outputDir = process.env.VUE_APP_BASE_FRONT_END_DIR || '/'
console.log(outputDir)
const routes = [
  {
    path: outputDir + '/',
    name: 'home',
    component: HomePage
  },
  {
    path: outputDir + '/:projectID',
    name: 'home-with-id',
    component: HomePage
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})


router.beforeEach((to, from, next) => {
  
  console.log(`Navigating to ${to.path}`)
  if (to.meta.notFound) {
    // The route has already been matched, so don't match it again
    return next(false)
  }
  next()
})


export default router
