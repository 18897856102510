<template>
    

    <v-snackbar
      v-for="error in allErrors"
        :key="error"
      :timeout="4000"
      :color="error.color"
      rounded="pill"
      v-model="active"
  
    >


      <p
       
      >
        {{ error.message }}
        <br/>
    </p>
      
    </v-snackbar>
</template>

<script>
export default {
  data(){
    return {
      active:false,
    }
  },
  mounted(){
    
  },  
  computed:{
    allErrors(){
          return this.$store.getters['errorData/allErrors'];
      }
    },
    watch:{
      allErrors(){
        console.log9
        this.active = true;
      }
    }
}
</script>
