import * as mutation from './mutationTypes'
import axios from '@/Services/axiosService'

const apiAddress_staking = process.env.VUE_APP_STAKING_SERVICE_BASE;

const stakeRewardsData  = {
    namespaced: true,
    state: {
        all:null,
        current:null,
    },
    getters: {
        current : state =>{
            return state.current;
        },
        all : state =>{
            return state.all;
        }
    },
    mutations: {
        [mutation.RESET_ALL] (state){
            state.all = null
            state.current = null
        },
        [mutation.SET_CURRENT] (state,data){
            let theData = JSON.parse(JSON.stringify(data))
            state.current = theData;
          },
          [mutation.SET_ALL] (state,data){
            let theData = JSON.parse(JSON.stringify(data))
            state.all = theData;
          },
    },
    actions: {
        resetRewards({commit},project_id){
            commit(mutation.RESET_ALL);
            setTimeout(() => {
                axios.post('/user-stake-rewards',{project_id})
                .then(resp=>{
                    commit(
                        mutation.SET_CURRENT,
                        resp.data
                    );
                    return resp.data;
                });    
            }, 500);
            
        },
        async withdraw({commit},data){
            return await axios.post(apiAddress_staking + '/staking/user/rewards/withdraw',data)
            .then(resp=>{
                console.log(commit);
                console.log(resp);
                // this.addAlert('Succesfully Claimed Tokens')
                commit(
                    mutation.SET_CURRENT,
                    resp.data
                );
                // return resp.data;
            },error=>{
                console.log(error)
                // this.addAlert('Failed to claim rewards')
            }
            
            );
        },
        get({commit},project_id){
            axios.post(apiAddress_staking + '/staking/user/rewards',{project_id})
            .then(resp=>{
                commit(
                    mutation.SET_CURRENT,
                    resp.data
                );
                return resp.data;
            });
        },
        getAll({commit}){
            axios.get('/user-stake-rewards/get')
            .then(resp=>{
                console.log("resp");
                commit(
                    mutation.SET_ALL,
                    resp.data
                );
            });
        },
        create({commit},data){
            
            axios.post('/user-stake-rewards/create',data).then(resp=>{
                commit(
                    mutation.SET_CURRENT,
                    resp.data
                );
                return resp;
            });
        }
    }
}
export {stakeRewardsData};
