import * as mutation from './mutationTypes'
import axios from '@/Services/axiosService'


const projectWalletData  = {
    namespaced:true,
    state: {
        all:null,
        current:null,
    },
    getters: {
        projectWalletCurrent : state =>{
            return state.current;
        },
        projectWalletAll : state =>{
            return state.all;
        }
    },
    mutations: {
        [mutation.SET_CURRENT] (state,data){
            let theData = JSON.parse(JSON.stringify(data))
            state.current = theData;
          },
          [mutation.SET_ALL] (state,data){
            let theData = JSON.parse(JSON.stringify(data))
            state.all = theData;
          },
    },
    actions: {
        pwGetAll({commit}){
            axios.get('/project-wallet/get')
            .then(resp=>{
                console.log("resp");
                commit(
                    mutation.SET_ALL,
                    resp.data
                );
            });
        },
        create({commit},data){
            
            axios.post('/project-wallet/create',data).then(resp=>{
                commit(
                    mutation.SET_CURRENT,
                    resp.data
                );
                return resp;
            });
        }
    }
}
export {projectWalletData};
