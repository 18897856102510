<template>
    <v-card :class=" performingAction ? 'assetCard performingAction' : 'assetCard'"   
    >
        <v-img
            :src="asset.image"
            cover
            class="text-white"
        >
            <div class="itemNameHolder">
                <span class="itemName" 
                    v-if="asset.uriData"
                    vertical
                >
                    {{asset.uriData.name}}
                </span>

               
            </div>
            <div class="loader">
                <loading-spinner 
                    v-if="asset.stakingStatus"
                    :loadingText = actionName
                />
            </div>
        </v-img>
        <h5 class="itemDescription" 
                v-if="asset.uriData"
                vertical
            >
                {{asset.uriData.description}}
        </h5>
        
        <button
            class="stakeButton glow-on-hover button" 
            :disabled="buttonEnabled"
            @click="stakeAsset(asset)"
            v-if="!asset.isStaked"
        >
            Stake
        </button>

        <button
            class="stakeButton glow-on-hover button" 
            :disabled="buttonEnabled"
            @click="withdrawStake(asset)"
            v-if="asset.isStaked"
        >
            Unstake
        </button>
        <br/>
        <v-icon
            @click="copyItem(asset.address)"
            end
            icon="mdi-clipboard-text"
        ></v-icon>

        <v-icon
        @click="nxsHelpers.searchOnSolscan(asset.address,{type:'token'})"
        end
        icon="mdi-card-search"
        ></v-icon>

    </v-card>
</template>

<script>
import TokenTransfer from '@/Services/TokenTransfer';

export default {
    props:[
        'asset'
    ],
    data(){
        return {
            buttonEnabled:false,
            performingAction:false,
            actionName:'Stakng' //TODO implement this
        }
    },
    methods:{
        withdrawStake(asset){
            asset.stakingStatus = true; // TODO wrong?
            this.buttonEnabled = false;
            this.actionName = 'Unstaking';
            this.performingAction = true;

            setTimeout(() => {
                if(this.localWallet){
                    this.$store.dispatch('stakeData/withdraw',
                    {
                        project_id:this.project.id,
                        token_address:asset.address,
                        local_wallet_id:this.localWallet._publicKey
                    }).then((resp) => {
                        if(resp){
                            console.log('was success',resp)
                            this.performingAction = false;
                            this.buttonEnabled = false;
                            asset.isStaked = false;
                            this.triggerRefresh()
                            this.nxsHelpers.createError('Succesfully unstaked ' + asset.address, 'green');
                            // this.addAlert('Succesfully unstaked ' + asset.address) !!!!!
                        }else{
                            console.log('not seen as a success', resp)
                            this.performingAction = false;
                            this.buttonEnabled = false;
                        }
                        
                    }, error => {
                        console.log(error)
                        this.performingAction = false;
                        this.buttonEnabled = false;
                        this.nxsHelpers.createError('There was an issue unstaking ' + asset.address);
                        
                    });
                }
            }, 2000);

        },
        triggerRefresh(){
            this.$store.dispatch('projectData/get',this.project.id).then(error => {console.log(error)}); //TODO p1 - Check that a use can't change this in the payload to change the project they are staking against, if they can, is that a problem?
        },
        getStakedAssets(){

        },
        copyItem(item){
            this.nxsHelpers.copyValueToClipboard(item);
            this.nxsHelpers.createError('Copied to clipboard ' + item, 'green')
        },
        async stakeAsset(asset){

            asset.stakingStatus = true;
            this.buttonEnabled = false;

            
            this.performingAction = true;
            
            this.$store.dispatch('userProjectWalletData/get',this.project.id).then(() => {
                
                
                setTimeout(() => {
                    if(this.currentUserProjectWallet){
                        TokenTransfer.transferSelectedToken(
                            asset.address,
                            this.currentUserProjectWallet.address
                        ).then((resp)=>{
                            if(resp.error){
                                // this.addAlert('Issue staking' + asset.address)
                                this.nxsHelpers.createError('Issue staking' + asset.address);
                                console.log(resp.error)
                            }else{
                                let transactionAddress = null;

                                try {
                                    transactionAddress = resp.transactionAddress;
                                } catch (error) {
                                    console.log(error)
                                }
                                this.$store.dispatch('stakeData/create',{
                                    project_id:this.project.id,
                                    token_address:asset.address,
                                    local_wallet_public_key:this.localWallet._publicKey, 
                                    transaction_id:transactionAddress // determine this actually happened
                                }).then(()=>{
                                    console.log('Succesfully staked ' + asset.address)
                                    this.nxsHelpers.createError('Succesfully staked ' + asset.address, 'green');
                                    // this.addAlert('Succesfully staked ' + asset.address)
                                    this.triggerRefresh() // TODO this always breaks the page load
                                });
                            }
                            this.performingAction = false;
                            this.buttonEnabled = false;
                        },error=>{
                            this.performingAction = false;
                            this.buttonEnabled = false;
                            this.nxsHelpers.createError('Issue staking' + asset.address);
                            // this.addAlert('Issue staking' + asset.address)
                            console.log(error)
                        });
                    }else{
                        console.log('Could not find a local wallet to stake ' + asset.address)
                        this.nxsHelpers.createError('Could not find a local wallet to stake');
                        // this.addAlert('Could not find a local wallet to stake ' + asset.address)    
                        this.performingAction = false;
                        this.buttonEnabled = false;
                    }
                }, 1000);
            }, error => {console.log(error)});

         
        }
    },
    mounted(){
        
    },
    computed:{
        project(){
            return this.$store.getters['projectData/current'];
        },
        localWallet () {
            return this.$store.getters['localWalletData/localWalletCurrent'];
        },
        currentUserProjectWallet(){
            return this.$store.getters['userProjectWalletData/current'];
        }
    }
    
}
</script>

<style>
    .assetCard .itemNameHolder{
        
        width: 100%;
        display: block;
        background: #280230;
        opacity: 0.7;
        padding: 0.5em;
    }

    

    .assetCard .button{
        width: 90%;
        height: 2.5em;
        margin: 1em 0 1em 0;
    }

    .assetCard{
        border-radius: 1em;
        padding-bottom: 2em;
        transition: width 2s;
    }

    .assetCard h5.itemDescription{
        margin-top: 1em;
        font-size: 1em;
    }

    .stakeButton{
        box-shadow: inset 0px 0px 0px 1px #333;
    }

    .assetCard .loader{
        display: block;
        height: 100%;
        width: 100%;
        background-color: black;
    }
    .loader{
        transition-duration: 500ms;
        opacity: 0;
    }
    .performingAction .loader{
        opacity: 0.8;
        background: black;
    }

   
    
</style>