import * as mutation from './mutationTypes'
import axios from '@/Services/axiosService'
import AssetDataService from '@/Services/ChainAssetData';

// const apiAddress_projects = process.env.VUE_APP_PROJECTS_SERVICE_BASE;
const apiAddress_projects = process.env.VUE_APP_PROJECTS_SERVICE_BASE;

const projectData = {
    namespaced: true,
    state: ()=>({ 
        allProjects:null,
        currentProject:null,
        projectStakableAssets:null, // TODO this could get out of sync
    }),
    mutations: {
        [mutation.SET_CURRENT] (state,data){
            let theData = JSON.parse(JSON.stringify(data))
            
            state.currentProject = theData;
          },
          [mutation.SET_ALL] (state,data){
            let theData = JSON.parse(JSON.stringify(data))
            state.allProjects = theData;
          },
          async [mutation.SET_STAKEABLE_ASSETS] (state,data){
            let addresses = [];
            let theData = JSON.parse(JSON.stringify(data))
            let tmpStakeableAssets  = {
                nfts:[],
                collections:[]
            };

            theData.nfts.forEach(element => {
                element.forEach(item => {
                    if(item.type == 'nft'){
                        tmpStakeableAssets.nfts.push(item.value);
                    }
    
                    if(item.type == 'nft_list'){
                        item.value.forEach(nftItem => {
                            addresses.push(nftItem);
                            tmpStakeableAssets.nfts.push({address:nftItem,meta:null});
                        });
                    }    
                });
                
            });
            
            if(tmpStakeableAssets.nfts.length > 0){
                
                
                await AssetDataService.getMetaDataFromTokenList(addresses).then((data)=>{
                    
                    data.forEach(metaElement => {
                        if(metaElement){
                            tmpStakeableAssets.nfts.forEach(stakeableAsset => {
                                
                                if(stakeableAsset.address == metaElement.mintAddress.toString()){
                                    // stakeableAsset.meta = metaElement;
                                    stakeableAsset.meta = metaElement;
                                }
                            });
                        }
                        
                    });
                });
                
            }
            console.log(tmpStakeableAssets,'tmpStakeableAssets')
            state.projectStakableAssets = tmpStakeableAssets;
          },
     },
    actions:{  
        get({commit},data){
            axios.get(apiAddress_projects + '/projects/' +data).then(resp=>{
                commit(mutation.SET_CURRENT,resp.data);
            },error=>{console.log(error)});
        },
        getAll({commit}){
            axios.get('/project/get').then(resp=>{
                commit(mutation.SET_ALL,resp.data);
                return resp;
            });
        },
        setCurrent({commit},data){
            commit(
                mutation.SET_CURRENT,
                data
            );   
        },
        getStakableAssets({commit},data){
            axios.post('/project/get/stakable-assets',data).then(resp =>{
                commit(mutation.SET_STAKEABLE_ASSETS,resp.data);
                return resp;
            });
        }
     },
    getters:{  
        all : state =>{
            return state.allProjects;
        },
        current : state =>{
            return state.currentProject;
        },
        stakableAssets : state =>{
            return state.projectStakableAssets;
        }
    }
}

export {projectData};