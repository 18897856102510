import store from '@/store/index'
import BrowserWalletService from './BrowserWalletService';
import TokenAvailability from './TokenAvailability';
import {PublicKey} from '@solana/web3.js'

// import TokenAvailability from '@/Services/TokenAvailability';
// import TokenTransfer from '@/Services/TokenTransfer';
// import ChainAssetDataService from '@/Services/ChainAssetData';
import axios from 'axios';
import helpers from '@/Services/helpers';
// import {PublicKey} from '@solana/web3.js'

export default {
    projectID:null,
    walletAssets:[],
    listMode:'stakeable',
    async getUserStakedAssets(projectID){ //TODO chck what happens for totally new user here wit hno stakes
        
        this.projectID = projectID;

        await store.dispatch('stakeData/getAll',
            {
                project_id:projectID
            }).then(() => {
            // this.getWalletAssets();
        }, error => {
            console.log(error);
                helpers.createError("There was an error fetching your assets");
        });
    },
    async getUserWalletAssets(){

        // let datas = [];
        // let usableAssetsInBrowserWallet = await this.getChainTokensInWalletAndAddStakedStatus();
        // let userAssetsMetaData = await ChainAssetDataService.getMetaDataFromTokenList(usableAssetsInBrowserWallet) // TODO check that this blockchain token list isn't paged

        // let userAssetsElligibleForProject = this.getUserAssetsElligibleForProject(userAssetsMetaData);
        // console.log('STARR')
        // userAssetsElligibleForProject.forEach(eligibleUserAsset => {
        //     // console.log("it 1")
        //     if(eligibleUserAsset){
           
        //         usableAssetsInBrowserWallet.forEach(walletAsset => {                    
        //             // console.log("it 2")
        //             if(walletAsset.address == new PublicKey(eligibleUserAsset.mintAddress).toString()){
        //                 walletAsset.metaData = eligibleUserAsset;
        //                 if(eligibleUserAsset.uri){
        //                     // this.getURIData(eligibleUserAsset,walletAsset)
        //                     // .then((returnData)=>{
                                
        //                     //     datas.push(returnData)
        //                     //     console.log('datas',datas)
        //                     // });
        //                     console.log('ADDIDND')
        //                 }
        //             }
        //         });
        //     }
            
        // });
        console.log('END')
        // console.log('this.walletAssets---',datas)
           
    },
    async getURIData(metaDataItem,walletAsset){
        

        let URIData = await axios.get(metaDataItem.uri)
        // .then(resp=>{
        //     let respData = resp.data;
        //     walletAsset.uriData = respData;
        //     walletAsset.image = respData.image;
    
        //     switch (this.listMode) {
        //         case 'stakeable':
        //                 if(!walletAsset.isStaked){
        //                     // this.walletAssets.push(walletAsset)
        //                 }        
        //             break;
        //             case 'staked':
        //             if(walletAsset.isStaked){
        //                     this.walletAssets.push(walletAsset)
        //                 }        
        //             break;
        //         default:
        //             break;
        //     }
        // });
        walletAsset.uriData = URIData.data;
        walletAsset.image = URIData.data.image;
        return walletAsset;

    },
    getUserAssetsElligibleForProject(userAssets){
        let filteredAssets = [];

        store.getters['projectData/current'].assets.forEach(projectAssetCollection => {
            
            if(projectAssetCollection.type == "Asset List" || projectAssetCollection.type == "Asset"){
                userAssets.forEach(userAsset => {
                    if(userAsset){
                        if( this.checkIfAddressInProjectAssetList(projectAssetCollection.values,new PublicKey(userAsset.address).toString())){
                            filteredAssets.push(userAsset);      
                        }
                    }
                });
            }

            if(projectAssetCollection.type == 'Collection'){
                // console.log('projectAssetCollection',projectAssetCollection)
                userAssets.forEach(userAsset => {
                    if(this.checkUserAssetBelongsToCollection(projectAssetCollection,userAsset)){
                        filteredAssets.push(userAsset);
                    }
                });
            }
        });

        return filteredAssets;
    },
    checkUserAssetBelongsToCollection(projectAssetItem,userAsset ){
        try {
            if(userAsset.collection.address.toString() == projectAssetItem.address ){
                return true;
            }
        } catch (error) {
            // console.log('issue here',error);
        }
        
        return false;
    },
    checkIfAddressInProjectAssetList(projectAssetItem, address){
        if(projectAssetItem == address){
            return true;
        }
        return false;
    },
    async getStakedUserAssets(projectID){
        await this.getUserStakedAssets(projectID);
          return store.getters['stakeData/all'];
        // .forEach(stakedAsset => {
           
        // });

    },
    async getChainTokensInWalletAndAddStakedStatus(){
        this.walletAssets = [];
        
        let walletAssets = await TokenAvailability.getAllTokensByWallet(BrowserWalletService.browserWalletPublicKey);
        
        // await store.getters['stakeData/all'].forEach(stakedAsset => {
        //     walletAssets.push(stakedAsset.token)    
        // });
        
        let useableAssets = [];
    
        walletAssets.forEach(walletAsset => {
            let staked = false;
          
            let newItem =  { 
                    address:walletAsset,
                    metaData:null,
                    image:null,
                    uriData:null,
                    isStaked:staked,
                    stakingStatus:false,
            }

            let toAdd  = false
            
            if(this.listMode == 'stakeable' && !newItem.isStaked ){
                toAdd = true;
            }

            if(this.listMode == 'staked' && newItem.isStaked){
                toAdd = true;
            }

            if(toAdd){
                useableAssets.push(newItem)
            }
            
        });

        
        return useableAssets;
        /**
         *  Returns an array of:
            address:walletAsset,
            metaData:null,
            image:null,
            uriData:null,
            isStaked:staked,
            stakingStatus:false,
         */
    },

}