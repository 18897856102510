<template>

  <v-container>
    <v-card
        class="rewardCard"
        transition="scale-transition"
        origin="center center"
        v-for="item in stakeRewards"
        :key="item.rewardAsset"
    >
        

        <div class="headerHolder">
            <span class="title">{{nxsHelpers.truncate(item.rewardAsset,5)}}</span>

            <v-icon
                class="copyIcon"
                end
                icon="mdi-clipboard-text"
                @click="copyValueToClipboard(item.rewardAsset)"
            ></v-icon>
        </div>

        <div class="rewardCountValueHolder">
            <span class="rewardCountValue glow">{{item.rewardAmount}}</span>
        </div>

        <v-btn
            v-if="(item.rewardAmount > 0 && claimStep == 0)"
            class="glow-on-hover button mx-auto"
            @click="claimStep=1"
        >
            Claim
        </v-btn>

        <v-row
            v-if="claimStep != 0"
        >
            <v-col cols="12">
                <v-row>
                    <v-col>
                        <h4 style="text-align:center">Are you sure?</h4>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6">
                        <v-btn
                            class="glow-on-hover button mx-auto"
                            @click="claimStep = 0"
                        >
                            No
                        </v-btn>
                    </v-col>
                    <v-col cols="6">
                        <v-btn
                        class="glow-on-hover button mx-auto"
                        @click="startClaim(item)"
                        >
                            Yes
                        </v-btn>
                    </v-col>
                </v-row>


            </v-col>
        </v-row>
        <div 
            :class=" loading ? 'rewardLoader loading' : 'rewardLoader'"   
        >
            <loading-spinner 
                :loadingText = actionName
            />
        </div>
    </v-card>
    <span
        v-if="!stakeRewards || stakeRewards.length <=0 "
    >
        You don't have any rewards yet. Try staking an NFT to get rewards!
    </span>
  </v-container>
</template>

<script>
export default {    
    data(){
        return {
            claimStep:0,
            actionName:'Claiming',
            loading:false
        }
    },
    mounted(){
        
    },
    methods: {
        startClaim(item){
            this.loading = true;
            this.claimStep = 2
            setTimeout(() => {
                this.$store.dispatch('stakeRewardsData/withdraw',
                {
                    project_id:this.project.id,
                    token:item.rewardAsset
                }
                ).then(()=>{ // TODO .p1. this does NOT differentiate from different rewards per project
                    this.loading = false;
                    this.claimStep = 0
                    this.nxsHelpers.createError('Succesfully Claimed Rewards', 'green');
                    // this.addAlert('Succesfully claimed')
                    // REfresh rewards:
                    // this.$store.dispatch('stakeRewardsData/resetRewards',this.project.id).then(error => {console.log(error)});
                }, error => {
                    this.nxsHelpers.createError('Error Claiming Token ' );
                    console.log(error)
                });
                
            }, 2000);
        }
    },
    computed:{
        stakeRewards(){
            return this.$store.getters['stakeRewardsData/current'];
        },
        project(){
            return this.$store.getters['projectData/current'];
        },
    },
    watch:{
        project(){
            this.$store.dispatch('stakeRewardsData/get',this.project.id).then(()=>{},error => {console.log(error)});
        }
    }
}
</script>

<style>
    .rewardLoader.loading{
        background-color: black !important;
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0.9;
    }
    .rewardLoader{
        display: none;
        opacity: 0;
        transition: 2.5s;
    }
    .rewardCard .button{
        display: block;
        box-shadow: inset 0px 0px 0px 1px #333
    }
    .rewardCard{
        padding: 1em;
        margin-bottom: 1em;
        height: 25%;
        
        -webkit-box-shadow: 0px 0px 48px 0px rgba(11,1,13,1);
        -moz-box-shadow: 0px 0px 48px 0px rgba(11,1,13,1);
        box-shadow: 0px 0px 48px 0px rgba(11,1,13,1);
    }

    .rewardCard .title{
        text-align: center;
        display: inline-block;
        width:90%;
    }
    .rewardCard .copyIcon{
        display: inline-block;
    }

    .rewardCard .headerHolder{
        display: block;
        width: 100%;
    }

    .rewardCard .rewardCountValueHolder{
        display: block;
    }



    .rewardList{
        text-align: left;
    }

    .rewardList>*{
        margin-bottom: 1em;
    }

    .rewardAmount {
        text-align: right;
        margin-left: -0.5em;
    }
    .rewardAmount .value{
        margin-left: 0.5em;
    }

    .rewardCountValue{
    display: block;
    width: 100%;
    padding: 0;
    /* text-shadow: 0 13.36px 8.896px #110a01, 0 -2px 1px rgb(90 90 90); */
    

    /* color: #c69220; */
    /* color: gold;
    text-shadow: 0 0 5px gold, 0 0 10px gold, 0 0 15px gold; */
  
    text-shadow:
    0 0 1px #fff,
    0 0 2px #fff,
    0 0 5px #0ff,
    0 0 10px #0ff,
    0 0 20px #0ff,
    0 0 30px #0ff,
    0 0 50px #0ff;

    /* animation: animate-count-glow 1000ms infinite ease-in-out; */


    line-height: 3em;
    text-align: center;
    font-weight: bold;
    font-size: 3em;
    }

    @keyframes animate-count-glow {
        from {

            /* font-size: 3em; */
            /* text-shadow:
            0 0 1px #fff,
            0 0 2px #fff,
            0 0 5px #0ff,
            0 0 10px #0ff,
            0 0 20px #0ff,
            0 0 30px #0ff,
            0 0 50px #0ff; */
        }
        to {
            /* font-size: 3.1em; */
            /* text-shadow:
            0 0 1px #fff,
            0 0 2px #fff,
            0 0 5px #0ff,
            0 0 10px #0ff,
            0 0 20px #0ff,
            0 0 30px #0ff,
            0 0 60px #0ff; */
        }
    }

</style>