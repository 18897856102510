<template>
    <v-row class="projectDetailBlock">
        <v-col cols="12">
          <div  v-if="project">
              <h1>
              {{ project.name }}
            </h1>
            <span>{{project.description}}</span>
          </div>
        </v-col>
    </v-row>
</template>

<script>
export default {
  data: () => ({
    
  }),
  mounted(){
  },
  methods:{
  },
  computed: {
    project(){
      return this.$store.getters['projectData/current'];
    }
  }
}
</script>

<style>
  .projectDetailBlock{
    padding: 4em;
  } 
</style>
