import * as mutation from './mutationTypes'
import axios from '@/Services/axiosService'



const apiAddress_staking = process.env.VUE_APP_STAKING_SERVICE_BASE;

const stakeData = {
    namespaced: true,
    state: ()=>({ 
        all:null,
        current:null,
    }),
    mutations: {
        [mutation.SET_CURRENT] (state,data){
            let theData = JSON.parse(JSON.stringify(data))
            state.current = theData;
          },
          [mutation.SET_ALL] (state,data){
        let theData = JSON.parse(JSON.stringify(data))
            state.all = theData;
          }
     },
    actions:{  
        get({commit},data){
            axios.post('/stake/get/one',data).then(resp=>{
                commit(mutation.SET_CURRENT,resp.data);
                return resp;
            });
        },
        create({commit},data){
            axios.post(apiAddress_staking + '/staking/user/stakes/create',data).then(resp =>{
                commit(mutation.SET_CURRENT,resp.data);
                return resp;
            });
        },
        async getAll({commit},data){
             const response = await axios.post(apiAddress_staking + '/staking/user/stakes/all',data)
             commit(mutation.SET_ALL,response.data);
             return response.data;
        },
        setCurrent({commit},data){
            commit(
                mutation.SET_CURRENT,
                data
            );   
        },
        async withdraw({commit},data){
            console.log(commit)
            return await axios.post(apiAddress_staking +'/staking/user/stakes/withdraw',data)
            
        }
     },
    getters:{  
        all : state =>{
            return state.all;
        },
        current : state =>{
            return state.current;
        }
    }
}

export {stakeData};