import {Connection, PublicKey} from '@solana/web3.js'
import { getAccount, TOKEN_PROGRAM_ID, AccountLayout} from '@solana/spl-token'
import { Buffer } from 'buffer';
window.Buffer = Buffer;

const connection = new Connection(process.env.VUE_APP_WEB3_ENDPOINT, 'confirmed');

const TokenAvailability = {

    async walletContainsToken(walletPublicKey
        //,tokenAddress
        ){
        const pk = new PublicKey(walletPublicKey)
        let  tokenAccount = await(getAccount(connection,pk));

        return tokenAccount;
    },

    async getAllTokensByWallet(walletPublicKey){
        let myTokens = [];
        const tokenAccounts = await connection.getTokenAccountsByOwner(
            new PublicKey(walletPublicKey),
            {
              programId: TOKEN_PROGRAM_ID,
            }
          );
            
          tokenAccounts.value.forEach((tokenAccount) => {
            const accountData = AccountLayout.decode(tokenAccount.account.data);
            // console.log(`${new PublicKey(accountData.mint)}   ${accountData.amount}`);
            
            if(accountData.amount > 0){
              myTokens.push(new PublicKey(accountData.mint).toString())
            }
            
          })
          return myTokens;
    }

}


export default TokenAvailability