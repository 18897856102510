import * as mutation from './mutationTypes'
import axios from '@/Services/axiosService'
import UserService from '@/Services/UserService';

const apiAddress_staking = process.env.VUE_APP_STAKING_SERVICE_BASE;

const userData  = {
    namespaced: true,
    state: {
        current:null,
        isLocal:true,
        localWallet:null,
        token:null
    },
    getters: {
        current : state =>{
            return state.current;
        }
    },
    mutations: {
        [mutation.SET_CURRENT] (state,data){
            let theData = JSON.parse(JSON.stringify(data))  
            state.current = theData;
          },
          
          [mutation.RESET_ALL_AUTH_DATA](state){
            state.currentUser = null,
            state.token = null
            UserService.removeAxiosAuthHeaderToStoredToken();
          }
    },
    actions: {
        async handleGuest({commit},data){
            
            let resp = await axios.post(apiAddress_staking + '/staking/user/guest',{
                projectId:data.project,
                user:data.user
            });// TODO how to handle errors?

            commit(
                mutation.SET_CURRENT,
                resp.data.user.user
            );

            return resp.data;
        },
        async getCurrentUser({commit},project_id){
            
            let resp = await axios.post(apiAddress_staking + '/staking/user/confirm',{project_id})
            // .then(resp =>{
            //     if(resp){ // TODO what do do here if no user is returned
            //         if(resp.status == 200){
            //             let user = resp.data.user
            //             let token = resp.data.authorisation.token
            //             commit(mutation.SET_CURRENT,user);
            //             commit(mutation.SET_APP_USER_TOKEN,token);
            //         }else{
            //             console.log('resettting coz ' + resp.status)
            //             commit(mutation.RESET_ALL_AUTH_DATA);
            //         }   
            //     }
            //     return resp.data;
            // }) .catch(() => {
            //     // handle error
            //     commit(mutation.RESET_ALL_AUTH_DATA);
            //   });
            if(resp.status == 200){
                let user = resp.data.user
                // let token = resp.data.user.authorisation.token
                commit(mutation.SET_CURRENT,user);
                // commit(mutation.SET_APP_USER_TOKEN,token);
            }else{
                commit(mutation.RESET_ALL_AUTH_DATA);
            }
            return resp.data;
        },
    }
}
export {userData};
