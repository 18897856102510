import {Connection, PublicKey} from '@solana/web3.js'
import { Metaplex } from "@metaplex-foundation/js";

// const connection = new Connection("https://api.devnet.solana.com", 'confirmed'); 
const connection = new Connection(process.env.VUE_APP_WEB3_ENDPOINT, 'confirmed'); 

const metaplex = new Metaplex(connection);

const AssetDataService = {

    async getMetaDataFromTokenList(tokenList){
        let publicKeyList = [];
        
        tokenList.forEach(element => {
            try {
                publicKeyList.push(new PublicKey(element.address));
            } catch (error) {
                console.log('errored out on: ',element,error);
            }
        });

        let metaResponse = await metaplex.nfts().findAllByMintList({
            mints: publicKeyList
        });
        return metaResponse;
    }

    

}

export default AssetDataService;