import axios from 'axios';
import helpers from './helpers';

const instance = axios.create({});

const appElement = document.getElementById('nxs-staking-staker');
let chain = appElement.getAttribute('data-blockchain');

instance.defaults.headers.common['Accept'] = 'application/json';
instance.defaults.headers.common['Chain'] = chain;

instance.interceptors.request.use((config) => {
  // Show the loading component when a network request is made
  // this.vueObject.$root.loading.show = true;
  return config;
});

// Add a response interceptor
instance.interceptors.response.use(function (response) {

    return response;
  }, function (error) {
    if (error.response.status === 400) {
      if(error.response.data && error.response.data.message){
        helpers.createError(error.response.data.message);
      }
    } else if (error.response.status === 404) {
      // Handle 404 Not Found
    } else {
      // Handle other errors
    }

    return Promise.reject(error);
  });

export default instance;