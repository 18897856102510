<template>
  <v-row class="stakeable-assets">
    <v-col cols="2">
        <v-list dense>
            <v-subheader>Stakable NFTS</v-subheader>
            <v-list-item-group
                class="item-group"
                v-model="selectedAsset"
                color="primary"
            >
                <v-list-item
                    class="item"
                    v-for="(item, i) in eligibleAssets"
                    :key="i"
                >
                    <v-list-item-content>
                        <v-img class="nft_image " :src="item.meta.image"/>
                        
                        <v-icon
                            large
                            @click="copyToClipboard(item.address)"
                            >
                                mdi-clipboard
                                
                        </v-icon>

                        <div v-if="item.owned">
                            <v-icon
                            large
                            color="green darken-2"
                            >
                                mdi-check
                            </v-icon>
                            
                            <v-btn
                                v-if="!item.staked"
                                @click="beginStake(item)"
                            >
                                Stake this NFT
                            </v-btn>

                        </div>
                        
                        <div v-if="!item.owned">
                            <v-icon
                            large
                            color="red darken-2"
                            >
                                mdi-alert-circle
                            </v-icon>
                        </div>

                    </v-list-item-content>
                </v-list-item>
            </v-list-item-group>
        </v-list>
    </v-col>
  </v-row>
</template>

<script>

import axios from 'axios';

import TokenAvailability from '@/Services/TokenAvailability'; // TODO should services be global?
import TokenTransfer from '@/Services/TokenTransfer';

export default {
    data(){
        return {
            eligibleAssets: [],
            selectedAsset: null,
        }
    },
    mounted(){
        console.log('stakeable assets!!')
        if(!this.stakableAssets){
            // this.getStakeableAssets();
        }else{
            this.prepareEligibleAssets();
        }
        setTimeout(() => {
                console.log('calling !')
                this.getStakeableAssets()
        }, 3000);
    },
    methods:{
        async beginStake(item){
            
            this.$store.dispatch('userProjectWalletData/get',this.currentProject.id).then(() => {
                setTimeout(() => {
                    if(this.currentUserProjectWallet){
                        TokenTransfer.transferSelectedToken(
                            item.address,
                            this.currentUserProjectWallet.address
                        ).then((resp)=>{
                            if(resp.error){
                                console.log("error!")
                            }else{
                                this.$store.dispatch('stakeData/create',{
                                    project_id:this.currentProject.id,
                                    token_address:item.address,
                                    local_wallet_public_key:this.localWallet._publicKey, // TODO always check wallet init!!
                                    transaction_id:resp.transactionAddress
                                });
                            }
                        },error=>{
                            console.log(error)
                        });
                    }else{
                        console.log("no wallet")    
                        // couldn't get wallet address
                    }
                }, 1000);
            }, error => {console.log(error)});
        },
        copyToClipboard(address){
            navigator.clipboard.writeText(address);
        },
        async prepareEligibleAssets(){
            await this.stakableAssets.nfts.forEach(nft => {
                if(nft.meta){
                    axios.get(nft.meta.uri).then(response => {
                        if(response.data.image){
                            nft.meta.image = response.data.image;
                            this.eligibleAssets.push(nft);
                            console.log('staleabaless'. this.stakableAssets)
                        }
                    })
                }
            });
            this.checkWalletForAsset();
        },
        getStakeableAssets(){
            this.stakableAssets = []    
            if(this.currentProject){
                this.$store.dispatch('projectData/getStakableAssets',{project_id:this.currentProject.id}).then(() => {
                    setTimeout(() => {
                        this.prepareEligibleAssets();
                    }, 2000);
                }, error => {console.log(error)});
            }
        },
        async checkWalletForAsset(){
            // TODO always check wallet is connected before accessing
            let myTokens = await TokenAvailability.getAllTokensByWallet(this.localWallet._publicKey).error(()=>{
                this.nxsHelpers.createError("There was an error fetching your assets");
            });
            
            for (let index = 0; index < this.eligibleAssets.length; index++) {
                
                myTokens.forEach(mytoken => {
                    if(mytoken == this.eligibleAssets[index].address){
                        this.eligibleAssets[index].owned = true;
                    }
                });   
            }
        },  
    },
    computed:{
        currentProject(){
            return this.$store.getters['projectData/current'];
        },  
        stakableAssets (){
            return this.$store.getters['projectData/stakableAssets'];
        },
        localWallet () {
            return this.$store.getters['localWalletData/localWalletCurrent'];
        },
        currentUserProjectWallet(){
            return this.$store.getters['userProjectWalletData/current'];
        }
    },
    watch:{
        currentProject(){
           this.getStakeableAssets(); 
        },
        stakableAssets(){
            this.prepareEligibleAssets();
        },
        localWallet(){
            // console.log("watched")
        }
    }
}
</script>

<style scoped>
.stakeable-assets .nft_image{
    border-radius: 1em;
}

.item{
    /* max-width:100px ; */
}
</style>